import React from 'react';
import { Helmet } from 'react-helmet';

const Head = (props) => {
    return (
        <Helmet
            htmlAttributes={{
                lang: 'en',
            }}
        >
            <title>{props.title}</title>
            <meta name='description' content={props.description}></meta>
            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
            <link rel='canocial' href='https://executivecoaching.sju.edu/'></link>
            <meta property='og:url' content='https://executivecoaching.sju.edu/' />
            <meta property='og:type' content='website' />
            <meta property='og:title' content="Saint Joseph's University Executive and Team Coaching Program" />
            <meta property='og:description'content="The Executive and Team Coaching Certification Program is designed to equip leaders with the skills to launch a successful executive coaching practice."/>
            <meta property='og:image' content='https://firebasestorage.googleapis.com/v0/b/ctedu-home-static.appspot.com/o/coach-training-logo.png?alt=media&token=38e4e0bd-5a5a-4e24-b128-2a5c73d9483f' />
            <meta property="og:image:url" content='https://firebasestorage.googleapis.com/v0/b/ctedu-home-static.appspot.com/o/coach-training-logo.png?alt=media&token=38e4e0bd-5a5a-4e24-b128-2a5c73d9483f' />
            <meta property="twitter:image" content='https://firebasestorage.googleapis.com/v0/b/ctedu-home-static.appspot.com/o/coach-training-logo.png?alt=media&token=38e4e0bd-5a5a-4e24-b128-2a5c73d9483f' />
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:creator" content="@Coachtrainedu"/>
            <meta property='og:site_name' content="Saint Joseph's University | Coach Training EDU, Certified Executive & Team Coaching Training" /> 
            <meta description='og:locale' content='en-US' />
            <script type="application/ld+json">
            {`{
                "@context": "https://schema.org/",
                "@type": "WebSite",
                "headline": "Saint Joseph's University Executive and Team Coaching Program",
                "description": "The Executive and Team Coaching Certification Program is designed to equip leaders with the skills to launch a successful executive coaching practice.",
                "image": "https://firebasestorage.googleapis.com/v0/b/ctedu-home-static.appspot.com/o/coach-training-logo.png?alt=media&token=38e4e0bd-5a5a-4e24-b128-2a5c73d9483f",
                "publisher": {
                    "@type": "CollegeOrUniversity",
                    name: "Saint Joseph's University"
                }
            }`}
            </script>
        </Helmet>
    )
}

export default Head